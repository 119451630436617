/***************************************************************************************************
*
* This file handles office-related actions and state using Redux Toolkit.
* 
* It allows:
* 
* 1. Asynchronous actions to add and fetch offices via API calls.
* 2. Managing the Redux state for offices, including error handling and resetting state.
* 3. Centralized control of the list of offices, enabling components to access and update office data.
* 
* Redux Toolkit simplifies Redux by combining actions, reducers, and async logic in one structure.
*
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createOffice, getOffices } from 'src/_helpers/api';

const name = 'offices';

const initialState = createInitialState();

const addOfficeAsync = createAsyncThunk(`${name}/add-office`, async (officeData) => {
  return await createOffice(officeData);
});

const getAllOfficesAsync = createAsyncThunk(`${name}/get-offices`, async () => {
  return await getOffices();
});

const officeSlice = createSlice({
  name,
  initialState: createInitialState(),
  reducers: {
    resetState: (state) => {
      state.offices = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOfficeAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(addOfficeAsync.fulfilled, (state, action) => {
        state.offices.push(action.payload);
      })
      .addCase(addOfficeAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(getAllOfficesAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(getAllOfficesAsync.fulfilled, (state, action) => {
        state.offices = action.payload.data || [];
      })
      .addCase(getAllOfficesAsync.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

function createInitialState() {
  return {
    offices: [],
    error: null,
  };
}

export const officeActions = {
  ...officeSlice.actions,
  getAllOfficesAsync,
  addOfficeAsync,
  resetState: officeSlice.actions.resetState,
};

export const officesReducer = officeSlice.reducer;
