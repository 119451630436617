/***************************************************************************************************
* This file configures the Redux store for the application, combining multiple reducers.
* 
* It allows:
* 
* 1. Integration of various feature reducers, such as authentication, users, UI, products, templates, etc.
* 2. Centralized state management, making the store accessible throughout the entire application.
* 3. Each slice manages its own part of the state, ensuring scalability and separation of concerns.
* 
* The store is set up using Redux Toolkit's `configureStore` for easier configuration and middleware integration.
***************************************************************************************************/

import { configureStore } from '@reduxjs/toolkit'

import { authReducer } from './auth.slice'
import { usersReducer } from './users.slice'
import { uiReducer } from './ui.slice'
import { productsReducer } from './products.slice'
import { templatesReducer } from './templates.slice'
import { projectsReducer } from './projects.slice'
import { stepsReducer } from './steps.slice'
import { quotationsReducer } from './quotations.slice'
import { submittalsReducer } from './submittals.slice'
import { revisionsReducer } from './revisions.slice'
import { parametersReducer } from './parameters.slice'
import { notificationsReducer } from './notifications.slice'
import { companiesReducer } from './companies.slice'
import { officesReducer } from './offices.slice'
import { locationsReducer } from './locations.slice'

export * from './auth.slice'
export * from './users.slice'
export * from './ui.slice'
export * from './templates.slice'
export * from './products.slice'
export * from './projects.slice'
export * from './steps.slice'
export * from './quotations.slice'
export * from './submittals.slice'
export * from './parameters.slice'
export * from './revisions.slice'
export * from './companies.slice'
export * from './offices.slice'
export * from './locations.slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    ui: uiReducer,
    templates: templatesReducer,
    projects: projectsReducer,
    products: productsReducer,
    steps: stepsReducer,
    quotations: quotationsReducer,
    submittals: submittalsReducer,
    revisions: revisionsReducer,
    notifications: notificationsReducer,
    parameters: parametersReducer,
    companies: companiesReducer,
    offices: officesReducer,
    locations: locationsReducer,
  },
})
