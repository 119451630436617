/***************************************************************************************************
* This file manages authentication-related logic using Redux. It allows:
* 
* 1. Handling user login, password reset, and password updates through async actions.
* 2. Storing user and token information in Redux state and cookies for session management.
* 3. Dispatching actions to fetch additional data (e.g., company information) upon login.
* 4. Managing token expiration and automatic logout.
* 
* Redux allows centralized state management, making it easier to share and update global data across 
* different components in the application.
***************************************************************************************************/

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history } from "../_helpers";
import {
  authenticate,
  resetPassword,
  sendPassword,
  setNewPassword,
  updatePassword,
} from "src/_helpers/api";
import {
  companyActions,
  productActions,
  submittalActions,
  quotationActions,
  userActions,
} from "src/_store";
import Cookies from "js-cookie";

const name = "auth";
const initialState = createInitialState();

const loginAsync = createAsyncThunk(
  `${name}/login`,
  async (useData, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await authenticate(useData);

      if (response.data) {
        const user = response.data.user;

        //console.log("user: ", user)

        await dispatch(companyActions.getAllCompaniesAsync());
        const { companies } = getState().companies;
        const userCompanyName = user.company;

        const matchedCompany = companies.find(
          (company) =>
            company.name.trim().toLowerCase() === userCompanyName.trim().toLowerCase(),
        );

        if (matchedCompany) {
          const countryValue =
            matchedCompany.clientType === "CANA" ||
            matchedCompany.clientType === "QC-BTI"
              ? 1
              : 2;
          const clientType = matchedCompany.clientType;

          user.country = countryValue;

          user.clientType = clientType;

          localStorage.setItem("user", JSON.stringify(user));
        }
      }

      Cookies.set("auth_token", response.data.token, {
        expires: 1,
        secure: false,
        sameSite: "Strict",
      });
      Cookies.set("token_expiration", response.data.token_expiration, {
        expires: 1,
        secure: false,
        sameSite: "Strict",
      });

      if (localStorage.getItem("stayLoggedIn")) {
        Cookies.set("auth_token", response.data.token, {
          expires: 7,
          secure: false,
          sameSite: "Strict",
        });
        Cookies.set("token_expiration", response.data.token_expiration, {
          expires: 7,
          secure: false,
          sameSite: "Strict",
        });
      }

      if (response.state === "temp_password") {
        sessionStorage.setItem("tempPassword", "true");
        history.navigate("/reset-password");
      }

      return response.data;
    } catch (error) {
      //console.error("Error during login: ", error);
      return rejectWithValue(error);
    }
  },
);

const sendPasswordAsync = createAsyncThunk(
  `${name}/generate-password`,
  async ({ email }) => {
    const response = await sendPassword(email);

    if (response.status === "success") {
      return response;
    } else {
      throw new Error(
        response.message || "Failed to send password reset email",
      );
    }
  },
);

const resetPasswordAsync = createAsyncThunk(
  `${name}/reset-password`,
  async ({ password, userId /*token*/ }) => {
    const response = await setNewPassword({ password, userId /*, token */ });

    if (response.success) {

    }
    return response.data;
  },
);

const updatePasswordAsync = createAsyncThunk(
  `${name}/update-password`,
  async ({ currentPassword, password, userId }, { rejectWithValue }) => {
    try {
      const response = await updatePassword({
        currentPassword,
        password,
        userId,
      });
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: error.message });
    }
  },
);

const resetPasswordRequestAsync = createAsyncThunk(
  `${name}/reset-password-request`,
  async (userId, { dispatch }) => {
    const response = await resetPassword(userId);

    if (response.success) {
      //console.log('Temp password:', response.data.temp_password)
    }

    return response.data;
  },
);

const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem("user");
      sessionStorage.clear();

      Cookies.remove("auth_token");
      Cookies.remove("token_expiration");

      state.user = null;
      state.token = null;
      state.userActions = null;

      history.navigate("/login");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.user = JSON.parse(localStorage.getItem("user"));
        state.token = Cookies.get("auth_token");

        const { from } = history.location.state || { from: { pathname: "/" } };
        history.navigate(from);
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

function createInitialState() {
  const stayLoggedIn = localStorage.getItem("stayLoggedIn");

  const user = JSON.parse(localStorage.getItem("user"));

  const token = stayLoggedIn
    ? Cookies.get("auth_token")
    : sessionStorage.getItem("auth_token");

  return {
    user: user,
    token: token,
    error: null,
    forgetPasswordMessage: null,
  };
}

export const { logout } = authSlice.actions;

export const authActions = {
  ...authSlice.actions,
  loginAsync,
  sendPasswordAsync,
  resetPasswordAsync,
  resetPasswordRequestAsync,
  updatePasswordAsync,
};

export const authReducer = authSlice.reducer;
