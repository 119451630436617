/***************************************************************************************************
 * 
 * This file defines the Redux slice for managing submittals and related records in the application.
 * 
 * It includes:
 * 
 * 1. Async thunks for creating, updating, and retrieving submittals, quotations, and related records.
 * 2. Reducers to handle pending, fulfilled, and rejected states for the API calls.
 * 3. Methods for deactivating and reactivating submittals, as well as managing submittal-related data.
 * 4. Initial state management for storing submittal data, related records, and error handling.
 * 
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  createSubmittal,
  getSubmittals,
  getSubmittalsByProduct,
  getSubmittalsByProductAndUserId,
  getQuotationsAndRevisions,
  desactivateSubmittal,
  reactivateSubmittal,
  updateQuotation,
  getRelatedRecords,
  changeSubmittalStatus
} from 'src/_helpers/api'

// create slice

const name = 'submittals'

const initialState = createInitialState()

const addSubmittalAsync = createAsyncThunk(`${name}/add-submittal`, async (submittalData, { rejectWithValue }) => {
  try {
    const response = await createSubmittal(submittalData);
    //console.log('🚀 ~ API response received:', response);
    return response;
  } catch (error) {
    //console.error('🚀 ~ Error occurred in addSubmittalAsync:', error);
    return rejectWithValue(error.response ? error.response.data : 'Unknown error');
  }
});

const updateSubmittalAsync = createAsyncThunk(`${name}/update-submittal`, async (submittalData) => {
//  console.log('🚀 ~ submittalData:', submittalData)
  return await updateQuotation(submittalData)
})

const getAllSubmittalsAsync = createAsyncThunk(`${name}/get-submittal`, async () => {
  return await getSubmittals()
})

const getSubmittalsByProductAsync = createAsyncThunk(
  `${name}/get-submittal-by-product`,
  async (productId) => {
    return await getSubmittalsByProduct(productId)
  },
)

const getSubmittalsByProductAndUserIdAsync = createAsyncThunk(
  `${name}/get-submittal-by-product-and-user-id`,
  async ({ productId, userId }) => {
    return await getSubmittalsByProductAndUserId(productId, userId);
  },
);

//////////// AJOUT ////////////
const getQuotationsAndRevisionsAsync = createAsyncThunk(
  `${name}/get-submittals-and-revisions`,
  async ({ userId }) => {
    return await getQuotationsAndRevisions(userId);
  },
);

//////////// AJOUT ////////////
const getRelatedRecordsAsync = createAsyncThunk(
  `${name}/get-related-records`,
  async () => {
    return await getRelatedRecords();
  },
);

const desactivateSubmittalAsync = createAsyncThunk(
  `${name}/desactivate-submittal`,
  async (submittalId) => {
    return await desactivateSubmittal(submittalId)
  },
)

const reactivateSubmittalAsync = createAsyncThunk(
  `${name}/reactivate-submittal`,
  async (submittalId) => {
    return await reactivateSubmittal(submittalId)
  },
)

//////////// AJOUT ////////////
const changeSubmittalStatusAsync = createAsyncThunk(
  `${name}/change-submittal-status`,
  async (submittalId) => {
    return await changeSubmittalStatus(submittalId)
  },
)

const submittalSlice = createSlice({
  name,
  initialState: createInitialState(), 
  reducers: {
    resetState: (state) => {
      state.submittals = []; 
      state.error = null; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSubmittalAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addSubmittalAsync.fulfilled, (state, action) => {
      //  console.log('🚀 ~ addSubmittalAsync.fulfilled ~ action:', action)
      })
      .addCase(addSubmittalAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllSubmittalsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllSubmittalsAsync.fulfilled, (state, action) => {
        state.allSubmittals = action.payload.data
      //  console.log('🚀 ~ getAllSubmittalsAsync.fulfilled ~ action:', action)
      })
      .addCase(getAllSubmittalsAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getSubmittalsByProductAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getSubmittalsByProductAsync.fulfilled, (state, action) => {
        state.submittals = action.payload.data
      //  console.log('🚀 ~ getSubmittalsByProductAsync.fulfilled ~ action:', action)
      })
      .addCase(getSubmittalsByProductAsync.rejected, (state, action) => {
        state.error = action.error
      })

      .addCase(getSubmittalsByProductAndUserIdAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getSubmittalsByProductAndUserIdAsync.fulfilled, (state, action) => {
        state.submittals = action.payload.data
      //  console.log('🚀 ~ getSubmittalsByProductAndUserIdAsync.fulfilled ~ action:', action)
      })
      .addCase(getSubmittalsByProductAndUserIdAsync.rejected, (state, action) => {
        state.error = action.error
      })

      .addCase(getQuotationsAndRevisionsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getQuotationsAndRevisionsAsync.fulfilled, (state, action) => {
        state.submittals = action.payload.data
      //  console.log('🚀 ~ getSubmittalAndRevisionsAsync.fulfilled ~ action:', action)
      })
      .addCase(getQuotationsAndRevisionsAsync.rejected, (state, action) => {
        state.error = action.error
      })

      .addCase(getRelatedRecordsAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(getRelatedRecordsAsync.fulfilled, (state, action) => {
        state.relatedRecords = action.payload.data;
      //  console.log('🚀 ~ getRelatedRecordsAsync.fulfilled ~ action:', action);
      })
      .addCase(getRelatedRecordsAsync.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(reactivateSubmittalAsync.pending, (state) => {
        state.error = null
      })
      .addCase(reactivateSubmittalAsync.fulfilled, (state, action) => {
      //  console.log('🚀 ~ reactivateSubmittalAsync.fulfilled ~ action:', action)
      })
      .addCase(reactivateSubmittalAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(desactivateSubmittalAsync.pending, (state) => {
        state.error = null
      })
      .addCase(desactivateSubmittalAsync.fulfilled, (state, action) => {
      //  console.log('🚀 ~ desactivateSubmittalAsync.fulfilled ~ action:', action)
      })
      .addCase(desactivateSubmittalAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(updateSubmittalAsync.pending, (state) => {
        state.error = null
      })
      .addCase(updateSubmittalAsync.fulfilled, (state, action) => {
      //  console.log('🚀 ~ updateSubmittalAsync.fulfilled ~ action:', action)
      })
      .addCase(updateSubmittalAsync.rejected, (state, action) => {
        state.error = action.error
      })

      .addCase(changeSubmittalStatusAsync.pending, (state) => {
        state.error = null
      })
      .addCase(changeSubmittalStatusAsync.fulfilled, (state, action) => {
        state.submittals = action.payload.data
      //  console.log('🚀 ~ changeSubmittalStatusAsync.fulfilled ~ action:', action)
      })
      .addCase(changeSubmittalStatusAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    submittals: [],
    allSubmittals: [], 
    relatedRecords: [],
    error: null,
  }
}

export const submittalActions = {
  ...submittalSlice.actions,
  getAllSubmittalsAsync,
  getSubmittalsByProductAsync,
  getSubmittalsByProductAndUserIdAsync,
  getQuotationsAndRevisionsAsync,
  getRelatedRecordsAsync,
  addSubmittalAsync,
  desactivateSubmittalAsync,
  reactivateSubmittalAsync,
  updateSubmittalAsync,
  changeSubmittalStatusAsync,
  resetState: submittalSlice.actions.resetState,}

export const submittalsReducer = submittalSlice.reducer
